<template>
  <div>
    <div v-for="(item, index) in prescription" :key="index">
      <div v-for="(val, key, ind) in item" :key="ind">
        {{ key + " " }}
        <template v-for="(v, k, i) in val">
          <span v-if="propertyVisible(k, v)" :key="i">
            <i v-if="v">{{ "(" }}</i>
            <i>{{ convertNewHardwareData(key, v) }}</i>
            <i v-if="v">{{ ")" }}</i>
          </span>
        </template>
        {{ " ;" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'listCard',
  props: {
    prescription: {
      type: Array,
      default: () => [],
    },
  },
  async created() {
    this.getData();
  },
  methods: {
    getData() {
      const list = this.prescription;
      for (let i = 0; i < list.length; i++) {
        if (Object.keys(list[i]) == '视觉专注训练') {
          const info = Object.values(list[i])[0] || {};
          if (info['训练类型'] == '追随训练') {
            info['训练类型'] = info['自主追随训练']
              ? '自主追随训练'
              : '引导追随训练';
          }
        }
      }
    },
    propertyVisible(key, value) {
      return !(key === '训练时长' && value.charAt(0) === '*');
    },
    convertNewHardwareData(type, content) {
      let result = content;
      if (type === '调节幅度训练'
        || type === '调节灵活度训练'
        || type === '镜片阅读训练') {
        result = content.replace(/2.50/, '2.00').replace(/1.50/, '1.00');
      }
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
</style>
