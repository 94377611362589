<template>
    <div class="scheme-library">
        <app-block-flex>
            <app-query-bar :queryConfig="queryConfig" v-model="queryData" @onSearch="fetchList" />
        </app-block-flex>
        <app-data-table :columns="LIBRARY_COLUMNS" :data="lists" v-model="queryData" @onPageConfigChange="fetchList"
            :allowed-permissions="Object.values(LIBRARY_ACTION)">
            <template slot-scope="{ row }" slot="time_len">
                {{ '预计' + row.time_len + '分钟' }}
            </template>
            <template slot-scope="{ row }" slot="prescription">
                  <listCard :prescription="JSON.parse(row.prescription || '[]') " />
            </template>
            <app-spacing spacing="sm" class="table-action" slot-scope="{ row }" slot="action">
                <Button v-if="permissions['product:library:info']" type="primary" @click="() => onEditInfo(row.id)">
                    编辑
                </Button>
            </app-spacing>
        </app-data-table>

        <edit-prescription :visible.sync="editModalData.editVisible" :customId="editModalData.customId"
            :prescriptionsList="editModalData.info" @on-cancel="onCancel" />
    </div>
</template>

<script>
import {
    LIBRARY_COLUMNS,
    LIBRARY_ACTION,
} from './constants';
import SchemeLibraryApi from './scheme-library.api';
import listCard from './listCard.vue';
import EditPrescription from './edit-prescription.component.vue';

export default {
    name: 'scheme-library',
    components: {
        listCard,
        EditPrescription,
    },
    computed: {
        userType() {
            return this.$store.getters.userType;
        },
        queryConfig() {
            return [
                {
                    fieldName: 'permission_name',
                    name: '方案名称',
                },
            ];
        },
    },
    data() {
        return {
            LIBRARY_COLUMNS,
            LIBRARY_ACTION,
            queryData: {
                page: 1,
                limit: 10,
                count: 0,
                userName: '',
            },
            editModalData: {
                editVisible: false,
                customId: NaN,
                info: {},
            },
            lists: [],
        };
    },
    created() {
        this.fetchList();
    },
    methods: {
        /* 方案库列表 */
        async fetchList() {
            const { page, limit, permission_name, } = this.queryData;
            const {
                count = 0,
                results = [],
            } = await SchemeLibraryApi.getList(
                {page: page,
                limit: limit,
                permission_name: permission_name,}
            );
            this.lists = results;
            this.$set(this.queryData, 'count', count);
        },
        /* 方案库详情 */
        async onEditInfo(id) {
            const result = await SchemeLibraryApi.getDetail(id);
            this.editModalData.info = {
                ...result,
                ...{ prescription: JSON.parse(result.prescription || '[]') },
            };
            this.editModalData.customId = id;
            this.editModalData.editVisible = true;
        },
        onCancel() {
            this.editModalData.editVisible = false;
            this.editModalData.info = {};
        },
        propertyVisible(key, value) {
            return !(key === '训练时长' && value.charAt(0) === '*');
        },
        getRow(info) {
            let key = info[0].key;
            let list = [{},{},{}];
        }
    },
};
</script>

<style>
.text {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
