import axios from '@/plugins/axios';

class SchemeLibraryApi {

  static getList(data) {
    return axios({
      url: '/api/product/library/page',
      method: 'post',
      data: { ...data },
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询方案列表',
        },
      },
    });
  }

  static save(customerId, data) {
    return axios({
      url: '/api/product/library/edit',
      method: 'post',
      data: { ...data, id: customerId },
      headers: {
        meta: {
          desc: '保存方案',
        },
      },
    });
  }

  static getDetail(Id) {
    return axios({
      url: `/api/product/library/info`,
      method: 'post',
      data: { id: Id },
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询方案详情',
        },
      },
    });
  }
  
}

export default SchemeLibraryApi;
