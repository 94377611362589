<template>
  <app-modal
    :value="visible"
    title="添加处方"
    :width="1024"
    @on-ok="() => $emit('on-ok')"
    @on-cancel="() => $emit('on-cancel')">
    <Form ref="form">
      <FormItem label="训练次数">
        <InputNumber
          v-model="repeatTime"
          controls-outside
          :min="1" />
      </FormItem>
      <FormItem label="请选择训练类型">
        <el-radio-group
          v-model="primaryClassification"
          type="button"
          button-style="solid"
          @change="(k) => setPrimaryLevelValue(k, CONSTANTS.primaryClassificationText[k])">
        <el-radio-button
            v-for="(v, k) in CONSTANTS.primaryClassificationText"
            :key="k"
            :label="k">
            {{v}}
          </el-radio-button>
        </el-radio-group>
      </FormItem>
      <FormItem
        v-if="secondaryClassificationConstantName"
        label="请选择训练项目">
        <el-radio-group
          v-model="secondaryClassification"
          type="button"
          button-style="solid"
          @change="(k) => setSecondLevelValue(
            k,
             CONSTANTS[secondaryClassificationConstantName][k],
          )">
          <el-radio-button
            v-for="(v, k) in CONSTANTS[secondaryClassificationConstantName]"
            :key="k"
            :label="k">
            {{v}}
          </el-radio-button>
        </el-radio-group>
      </FormItem>
      <FormItem
        v-for="(v, k) in CONSTANTS[secondarySubTitleConstantNames]"
        :key="k"
        :label="v">
        <template v-if="secondLevelIsMaxSingleSelection">
        </template>
        <el-radio-group
          v-if="secondLevelIsMaxSingleSelection"
          v-model="threeLevelDynamicValueObject[v]"
          type="button"
          button-style="solid"
          @change="(sv) => setThreeLevelDynamicValueByDynamicName(v, sv)">
          <el-radio-button
            v-for="(sv, sk) in threeLevelDynamicOptionsCache[k]"
            :key="sk"
            :label="sv">
            {{sv}}
          </el-radio-button>
        </el-radio-group>
        <el-radio-group
          v-else
          v-model="threeLevelClassification"
          type="button"
          button-style="solid"
          @change="(sk) => setThreeLevelValue(sk, threeLevelDynamicOptionsCache[k][sk], v)">
          <el-radio-button
            v-for="(sv, sk) in threeLevelDynamicOptionsCache[k]"
            :key="sk"
            :label="sk">
            {{sv}}
          </el-radio-button>
        </el-radio-group>
      </FormItem>
      <FormItem
        v-for="(v, k) in CONSTANTS[threeLevelSubTitleConstantNames]"
        :key="k"
        :label="convertNewHardwareTitle(v)">
        <el-radio-group
          :class="chooseOneMore && (!!fourLevelDynamicValueObject[v] ? '' : 'look-like-disable')"
          v-model="fourLevelDynamicValueObject[v]"
          type="button"
          button-style="solid"
          @change="(cv) => setFourLevelDynamicValueByDynamicName(
            v,
            cv,
            k,
            CONSTANTS[threeLevelSubTitleConstantNames],
          )">
          <el-radio-button
            v-for="(sv, sk) in fourLevelDynamicOptionsCache[k]"
            :key="sk"
            :label="sv">
            {{convertNewHardwareData(sv)}}
          </el-radio-button>
        </el-radio-group>
      </FormItem>
    </Form>
    <template slot="footer">
      <Button
        type="primary"
        :disabled="saveButtonIsDisable"
        @click="onOk">
        确认
      </Button>
      <Button @click="() => $emit('on-cancel')">
        取消
      </Button>
    </template>
  </app-modal>
</template>

<script>
import CONSTANTS from './create-training-program.constants';
import { upperCaseStringFirstChar } from './helper';

export default {
  name: 'create-prescription',
  props: {
    visible: {
      type: Boolean,
    },
  },
  watch: {
    visible(v) {
      if (!v) {
        this.resetData();
      }
    },
    secondarySubTitleConstantNames(v) {
      const fieldProps = CONSTANTS[v] || {};
      const keys = Object.keys(fieldProps);
      this.generateThreeLevelDynamicOptionsCache(keys, fieldProps);
      this.setThreeLevelValueObjectDynamicKey();
    },
    threeLevelSubTitleConstantNames(v) {
      const fieldProps = CONSTANTS[v] || {};
      const keys = Object.keys(fieldProps);
      this.generateFourLevelDynamicOptionsCache(keys, fieldProps);
      this.setFourLevelValueObjectDynamicKey();
    },
  },
  computed: {
    secondaryClassificationConstantName() {
      return this.primaryClassification && `${this.primaryClassification}SecondaryClassificationText`;
    },
    secondarySubTitleConstantNames() {
      return this.secondaryClassification && `${this.secondaryClassification}SecondarySubTitleText`;
    },
    threeLevelSubTitleConstantNames() {
      return this.secondaryClassification
        && this.threeLevelClassification
        && `${this.secondaryClassification}${upperCaseStringFirstChar(this.threeLevelClassification)}ThreeSubTitleText`;
    },
    secondLevelIsMaxSingleSelection() {
      return Object.keys(this.threeLevelDynamicOptionsCache).length > 1;
    },
    chooseOneMore() {
      return CONSTANTS[this.threeLevelSubTitleConstantNames]
      === CONSTANTS.visualFocusTrainingTrackingTrainingThreeSubTitleText;
    },
    translateKeys() {
      return [
        ...(this.secondLevelIsMaxSingleSelection
          ? Object.values(this.threeLevelDynamicTranslateKeys) : []),
        ...Object.values(this.fourLevelDynamicTranslateKeys),
      ];
    },
    emptyThreeLevelDynamicFiled() {
      return Object.keys(this.threeLevelDynamicTranslateKeys).length === 0;
    },
    emptyValueLen() {
      return this.translateKeys.filter((tk) => (!this.threeLevelDynamicValueObject[tk]
        && !this.fourLevelDynamicValueObject[tk])).length;
    },
    saveButtonIsDisable() {
      let flag = false;
      const noEmptyValue = (this.threeLevelClassification === 'trackingTraining' && this.emptyValueLen === 1)
        || this.emptyValueLen === 0;

      flag = !!this.primaryClassification
        && !!this.secondaryClassification
        && noEmptyValue;

      if (!this.secondLevelIsMaxSingleSelection && !this.emptyThreeLevelDynamicFiled) {
        flag = flag && this.threeLevelClassification;
      }

      return !flag;
    },
  },
  data() {
    return {
      CONSTANTS,
      repeatTime: 1,
      primaryClassification: '',
      primaryClassificationText: '',
      secondaryClassification: '',
      secondaryClassificationText: '',
      threeLevelClassification: '',
      threeLevelClassificationKey: '',
      threeLevelClassificationText: '',
      threeLevelDynamicOptionsCache: {},
      threeLevelDynamicTranslateKeys: {},
      threeLevelDynamicValueObject: {},
      fourLevelDynamicOptionsCache: {},
      fourLevelDynamicTranslateKeys: {},
      fourLevelDynamicValueObject: {},
      threeLevelSubFieldByTimeLenField: '',
      realEmptyThreeLevelValueLen: 0,
      realEmptyFourLevelValueLen: 0,
    };
  },
  methods: {
    resetData() {
      this.repeatTime = 1;
      this.primaryClassification = '';
      this.primaryClassificationText = '';
      this.secondaryClassification = '';
      this.secondaryClassificationText = '';
      this.threeLevelClassification = '';
      this.threeLevelClassificationKey = '';
      this.threeLevelClassificationText = '';
      this.threeLevelDynamicOptionsCache = {};
      this.threeLevelDynamicTranslateKeys = {};
      this.threeLevelDynamicValueObject = {};
      this.fourLevelDynamicOptionsCache = {};
      this.fourLevelDynamicTranslateKeys = {};
      this.fourLevelDynamicValueObject = {};
      this.threeLevelSubFieldByTimeLenField = '';
      this.realEmptyThreeLevelValueLen = 0;
      this.realEmptyFourLevelValueLen = 0;
    },
    generateThreeLevelDynamicOptionsCache(keys, fieldProps) {
      this.threeLevelDynamicOptionsCache = {};
      this.threeLevelDynamicTranslateKeys = {};
      keys.forEach((key) => {
        const constantName = `${this.secondaryClassification}${upperCaseStringFirstChar(key)}ThreeLevelClassificationText`;
        this.$set(this.threeLevelDynamicOptionsCache, key, CONSTANTS[constantName]);
        this.$set(this.threeLevelDynamicTranslateKeys, key, fieldProps[key]);
      });
    },
    setThreeLevelValueObjectDynamicKey() {
      const threeLevelDynamicValueObject = {};

      if (this.secondLevelIsMaxSingleSelection) {
        Object.values(this.threeLevelDynamicTranslateKeys).forEach((translatedKey) => {
          this.threeLevelDynamicValueObject[translatedKey] = '';
        });
      }

      this.threeLevelDynamicValueObject = threeLevelDynamicValueObject;
    },
    generateFourLevelDynamicOptionsCache(keys, fieldProps) {
      this.fourLevelDynamicOptionsCache = {};
      this.fourLevelDynamicTranslateKeys = {};
      keys.forEach((key) => {
        const constantName = `${this.secondaryClassification
          + upperCaseStringFirstChar(this.threeLevelClassification)
          + upperCaseStringFirstChar(key)
        }FourLevelClassificationText`;
        this.$set(this.fourLevelDynamicOptionsCache, key, CONSTANTS[constantName]);
        this.$set(this.fourLevelDynamicTranslateKeys, key, fieldProps[key]);
      });
    },
    setFourLevelValueObjectDynamicKey() {
      const fourLevelDynamicValueObject = {};

      if (!this.secondLevelIsMaxSingleSelection) {
        Object.values(this.fourLevelDynamicTranslateKeys).forEach((translatedKey) => {
          this.fourLevelDynamicValueObject[translatedKey] = '';
        });
      }

      this.fourLevelDynamicValueObject = fourLevelDynamicValueObject;
    },
    setPrimaryLevelValue(k, v) {
      this.primaryClassification = k;
      this.primaryClassificationText = v;
      this.secondaryClassification = '';
      this.secondaryClassificationText = '';
      this.threeLevelClassification = '';
      this.threeLevelClassificationKey = '';
      this.threeLevelClassificationText = '';
      this.threeLevelDynamicValueObject = {};
    },
    setSecondLevelValue(k, v) {
      this.secondaryClassification = k;
      this.secondaryClassificationText = v;
      this.threeLevelClassification = '';
      this.threeLevelClassificationKey = '';
      this.threeLevelClassificationText = '';
      this.threeLevelDynamicValueObject = {};
    },
    setThreeLevelValue(k, v, fieldName) {
      this.threeLevelDynamicValueObject = {};
      this.fourLevelDynamicValueObject = {};
      this.threeLevelClassification = k;
      this.threeLevelClassificationKey = fieldName;
      this.threeLevelClassificationText = v;
    },
    setThreeLevelDynamicValueByDynamicName(field, v) {
      this.threeLevelClassification = '';
      this.fourLevelDynamicValueObject = {};
      this.threeLevelDynamicValueObject[field] = v;
      // this.$set(this.threeLevelDynamicValueObject, field, v);
    },
    setFourLevelDynamicValueByDynamicName(translateField, value, field, siblingFields) {
      if (CONSTANTS.visualFocusTrainingTrackingTrainingThreeSubTitleText === siblingFields) {
        this.threeLevelSubFieldByTimeLenField = field;
        Object.values(siblingFields).forEach((tk) => {
          if (tk !== translateField) {
            this.$delete(this.fourLevelDynamicValueObject, tk);
          }
        });
      } else {
        this.threeLevelSubFieldByTimeLenField = '';
      }

      this.fourLevelDynamicValueObject[translateField] = value;
      // this.$set(this.fourLevelDynamicValueObject, translateField, value);
    },
    getTimeLength() {
      const isSelected = [
        ...Object.keys(this.threeLevelDynamicValueObject),
        ...Object.keys(this.fourLevelDynamicValueObject),
      ].includes('训练时长');

      if (isSelected) {
        return this.threeLevelDynamicValueObject['训练时长'] || this.fourLevelDynamicValueObject['训练时长'];
      }

      if (!this.secondLevelIsMaxSingleSelection) {
        const [key] = Object.keys(this.threeLevelDynamicTranslateKeys);
        const constantName = this.secondaryClassification
          + (key ? upperCaseStringFirstChar(key) : '')
          + (this.threeLevelClassification ? upperCaseStringFirstChar(this.threeLevelClassification) : '');

        if (CONSTANTS.fixedTrainingTime[constantName]) {
          return CONSTANTS.fixedTrainingTime[constantName];
        }
      }

      return CONSTANTS.fixedTrainingTime[this.secondaryClassification]
        || CONSTANTS.fixedTrainingTime[
          this.secondaryClassification
          + (this.threeLevelClassification
            && upperCaseStringFirstChar(this.threeLevelClassification))
          + (this.threeLevelSubFieldByTimeLenField
            && upperCaseStringFirstChar(this.threeLevelSubFieldByTimeLenField))
        ];
    },
    onOk() {
      const timeLenStr = this.getTimeLength();
      const trainingProgramInfo = {
        ...this.threeLevelDynamicValueObject,
        ...this.fourLevelDynamicValueObject,
        训练时长: timeLenStr,
      };

      let realTimeLenStr = timeLenStr;
      if (timeLenStr.charAt(0) === '*') {
        realTimeLenStr = timeLenStr.substring(1);
        // delete trainingProgramInfo['训练时长'];
      }
      // eslint-disable-next-line radix
      const timeLen = Number.parseInt(realTimeLenStr);

      if (!this.secondLevelIsMaxSingleSelection && this.threeLevelClassificationKey) {
        // trainingProgramType.value = this.threeLevelClassificationText;
        trainingProgramInfo[this.threeLevelClassificationKey] = this.threeLevelClassificationText;
      }

      const basicProgram = { [this.secondaryClassificationText]: trainingProgramInfo };

      const programs = [];

      for (let index = 0; index < this.repeatTime; index += 1) {
        programs.push(basicProgram);
      }

      const totalTimeLen = timeLen * this.repeatTime;

      const data = {
        time_len: totalTimeLen,
        prescription: programs,
      };

      this.$emit('on-ok', data);
    },

    convertNewHardwareData(sv) {
      let result = sv;
      if (this.secondaryClassificationText === '调节幅度训练'
       || this.secondaryClassificationText === '调节灵活度训练'
       || this.secondaryClassificationText === '镜片阅读训练') {
        result = sv.replace(/2.50/, '2.00').replace(/1.50/, '1.00');
      }
      return result;
    },
    convertNewHardwareTitle(title) {
      let result = title;
      if (this.secondaryClassificationText === '镜片阅读训练' && title === '初始训练难度') {
        result = '初始难度等级';
      }
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
.look-like-disable .ivu-radio-group-item {
  background-color: #eee;
  &:hover {
    color: #515a6e;
  }
}
</style>
