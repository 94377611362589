export const LIBRARY_COLUMNS = [
    {
        title: '方案名称',
        key: 'permission_name',
        minWidth: 100,
    },
    {
        title: '训练项目',
        key: 'prescription',
        slot: 'prescription',
        minWidth: 400,
    },
    {
        title: '训练时长',
        key: 'time_len',
        slot: 'time_len',
        minWidth: 60,
    },
    {
        title: '操作',
        key: 'action',
        slot: 'action',
        width: 140,
        fixed: 'right',
    },
];

export const LIBRARY_ACTION = {
    EDIT: 'product:library:info',
};