<template>
  <div>
    <app-modal
      class="item_modal"
      :value="visible"
      title="编辑方案"
      width="800"
      @on-cancel="() => $emit('on-cancel')"
    >
      <div class="head">
        <h3 style="float: left">{{ "方案名称" }}</h3>
        <h3 style="float: left; margin-left: 20px">
          {{ prescriptionsList.permission_name }}
        </h3>
      </div>
      <div class="head">
        <h3 style="float: left">方案内容</h3>
        <h3 style="float: left; margin-left: 20px; color: #61bfe7">
          {{ getOperationTitle(prescriptionsList) }}
        </h3>
        <Button
          type="warning"
          ghost
          @click="() => toggleCreatePrescriptionVisible(true)"
          class="btn_add"
        >
          添加训练项目
        </Button>
      </div>
      <taskCard
        :prescription="prescriptionsList.prescription"
        @upClick="upClick"
        @downClick="downClick"
        @del="del"
      />

      <div slot="footer" class="footers">
        <Button
          type="info"
          size="large"
          class="btn_close"
          @click="() => this.$emit('on-cancel')"
        >
          取消
        </Button>
        <Button
          type="primary"
          size="large"
          class="btn_save"
          @click="save"
          :disabled="
            !(
              prescriptionsList.prescription &&
              prescriptionsList.prescription.length
            )
          "
          >保存</Button
        >
      </div>
    </app-modal>
    <create-training-program
      :visible="createPrescriptionVisible"
      @on-ok="onCreatePrescription"
      @on-cancel="() => toggleCreatePrescriptionVisible(false)"
    />
  </div>
</template>

<script>
import moment from "moment";
import taskCard from "./taskCard.vue";
import CreateTrainingProgram from "./create-training-program.component.vue";
import SchemeLibraryApi from "./scheme-library.api";

const HISTORIES_COLUMNS = [
  { key: "opt_time", slot: "operationTime", width: 240 },
  { key: "operations", slot: "operations", width: 120 },
];

export default {
  name: "edit-prescription.component.vue",
  components: {
    taskCard,
    CreateTrainingProgram,
  },
  props: {
    visible: {
      type: Boolean,
    },
    prescriptionsList: {
      type: Object,
    },
    customId: {
      type: Number,
    },
  },
  data() {
    return {
      HISTORIES_COLUMNS,
      prescription: [],
      createPrescriptionVisible: false,
      previewHistoriesVisible: false,
      histories: [],
      previewHistoryDetailVisible: false,
      historyDetail: {},
    };
  },
  methods: {
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    upClick(index) {
      if (!index) return;
      const newArr = this.swapItems(
        this.prescriptionsList.prescription,
        index,
        index - 1
      );
      this.$set(this.prescriptionsList, "prescription", newArr);
    },
    // 下移
    downClick(index) {
      if (this.prescriptionsList.prescription.length === index + 1) return;
      const newArr = this.swapItems(
        this.prescriptionsList.prescription,
        index,
        index + 1
      );
      this.$set(this.prescriptionsList, "prescription", newArr);
    },
    del(index) {
      const timeLen = Number.parseInt(this.prescriptionsList.time_len) || 0;
      const newArr = [...this.prescriptionsList.prescription];
      const delTimeLen =
        Number.parseInt(
          Object.values(newArr[index])[0]["训练时长"]?.replace("*", "")
        ) || 0;
      const newTimeLen = timeLen - delTimeLen;
      newArr.splice(index, 1);
      this.$set(this.prescriptionsList, "prescription", newArr);
      this.$set(this.prescriptionsList, "time_len", newTimeLen);
    },
    async save() {
      if (this.prescriptionsList.time_len > 60) {
        this.$Message.error("预计时长大于60分钟");
        return;
      }
      if (this.prescriptionsList.prescription.length > 30) {
        this.$Message.error("训练次数大于30次");
        return;
      }
      await SchemeLibraryApi.save(this.customId, this.prescriptionsList);
      this.$emit("on-cancel");
      window.location.reload();
    },
    toggleCreatePrescriptionVisible(visible = false) {
      if (this.prescriptionsList.time_len > 60) {
        this.$Message.error("预计时长大于60分钟");
        return;
      }
      this.createPrescriptionVisible = visible;
    },
    onCreatePrescription(data) {
      const { time_len: createTimeLen, prescription } = data;
      const timeLen = Number.parseInt(this.prescriptionsList.time_len) || 0;
      const newArr = [...this.prescriptionsList.prescription, ...prescription];
      const newTimeLen = timeLen + createTimeLen;
      if(newTimeLen > 60) {
        this.$Message.error("预计时长大于60分钟");
        return;
      } 
      this.$set(this.prescriptionsList, "prescription", newArr);
      this.$set(this.prescriptionsList, "time_len", newTimeLen);
      this.toggleCreatePrescriptionVisible(false);
    },
    getOperationName(time) {
      return `${moment(time, "YYYY-MM-DD HH:mm:ss").format(
        "YYYY/MM/DD HH:mm:ss"
      )}的方案`;
    },
    getOperationTitle(detail) {
      return `共${detail.prescription?.length || 0}项训练，预计${
        detail.time_len || 0
      }分钟`;
    },
  },
};
</script>

<style scoped lang="less">
.actionBtn button {
  color: #5b8cff;
}

/deep/ .ivu-cell-main {
  display: block;
}

/deep/ .ivu-cell-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div:nth-child(1) {
    width: 70%;
  }
}

/deep/.ivu-card-body {
  height: 60vh;
  overflow-y: auto;
}

.cardHead {
  display: flex;
  justify-content: space-between;
}

.footers {
  display: flex;
  justify-content: center;
}

.head {
  height: 40px;
  width: 100%;
  margin-bottom: @spacingSM;
}

.title {
  color: #61bfe7;
  margin-bottom: @spacingSM;
}

.btn_add,
.btn_add:hover {
  color: #fff;
  background-color: darkorange;
  float: right;
  height: 40px;
}

.btn_close,
.btn_close:hover {
  width: 140px;
  height: 40px;
  color: #fff;
  border: none;
  background-color: rgb(201, 197, 197);
  font-weight: 500;
}

.btn_save,
.btn_save:hover {
  width: 140px;
  height: 40px;
  color: #fff;
  margin-left: 20px;
  background-color: #4797cd;
  border: none;
  font-weight: 500;
}
</style>
